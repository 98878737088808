import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { HeaderStyle } from "../../components/styles/main"
import Button from "react-bootstrap/Button"

const ListingWrapper = styled.div`
  padding: 1rem 2rem;
`
const BlogPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout>
      <SEO title="Blog" />
      <ListingWrapper>
        <HeaderStyle>HRPC Blog</HeaderStyle>
        {posts.map((element, index) => {
          const post = element.node
          return (
            <Row style={{ marginBottom: `3rem` }} key={index}>
              <Col xs="10">
                <h3>{post.frontmatter.title}</h3>
                <p>{post.excerpt}</p>
              </Col>
              <Col xs="2">
                <Link to={post.frontmatter.path}>
                  <Button
                    variant="secondary"
                    style={{ bottom: 0, position: `absolute` }}
                  >
                    Read More
                  </Button>
                </Link>
              </Col>
            </Row>
          )
        })}
      </ListingWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { template: { eq: "blog" } } }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          frontmatter {
            title
            path
            by
          }
        }
      }
    }
  }
`
export default BlogPage
